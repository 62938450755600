import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

const Home = lazy(() => import('./App'));
const Driver = lazy(() => import('./components/Driver/Driver'));
const Rider = lazy(() => import('./components/Rider/Rider'));

ReactDOM.render(
  <Suspense fallback={null}>
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/driver/" component={Driver} />
        <Route path="/rider/" component={Rider} />
      </Switch>
    </Router>
  </Suspense>,
  document.getElementById('wrapper'),
);
